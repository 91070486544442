import React, { useState } from "react";
import { Layout, Menu, Image, Button } from "antd";
import { Base64 } from "js-base64";
import "./Layout.css";
import sb_closed from "../../../assets/svg/Vector.svg";
import logo from "../../../assets/img/logo-sb.png";
import { useNavigate } from "react-router-dom";
import {
  HomeFilled,
  SettingFilled,
  LockFilled,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Logout } from "../../../functions/Logout";
const { Header, Content, Sider } = Layout;

const LayoutG = ({ content }) => {
  const navigate = useNavigate();

  const [closed, setclosed] = useState(true);
  const [rol] = useState(Base64.decode(localStorage.getItem("r0l")));

  const additionalMenuI = {
    key: "/usuarios",
    icon: <i className="fa-solid fa-user"></i>,
    label: "Usuarios",
    children: "",
  };

  let menu_items = [
    {
      key: "/dashboard",
      icon: <HomeFilled />,
      label: "Inicio",
      children: "",
    },
    {
      icon: <SettingFilled />,
      label: "Configuración",
      children: [
        {
          key: "/chg-pwd",
          icon: <LockFilled />,
          label: "Cambiar Contraseña",
        },
      ],
    },
  ];

  if (rol === "004") {
    menu_items = menu_items.concat(additionalMenuI);
  }

  const onCollapse = () => {
    setclosed(!closed);
  };

  // fx when it is closed

  const onSelectMenu = (item) => {
    navigate(`${item.key}`, { replace: true });
  };

  return (
    <Layout hasSider>
      {closed && (
        <div onClick={onCollapse} className="sider-btn">
          <Image src={sb_closed} preview={false} alt="logo" />
        </div>
      )}

      <Sider
        className="sider"
        width={250}
        collapsedWidth="0"
        collapsed={closed}
        onCollapse={onCollapse}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="sider-logo">
          <Image src={logo} preview={false} alt="logo" />

          <div
            onClick={() => {
              setclosed(true);
            }}
            className={"sider-toggle"}
          >
            <MenuFoldOutlined className={"sider-menu-icon"} />
          </div>
        </div>

        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          onClick={onSelectMenu}
          className={"sider-menu"}
          items={menu_items}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: !closed ? "250px" : "",
        }}
      >
        <Header className={"layout-header"}>
          <div className="l-header">
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <Button
                shape="circle"
                className="logout-btn"
                type="primary"
                onClick={() => {
                  Logout();
                  navigate("/", { replace: true });
                }}
              >
                <i className="fa-solid fa-right-to-bracket"></i>
              </Button>
            </div>
          </div>
        </Header>
        <Content
          className={"layout-content"}
          style={{ margin: "24px 16px 0", overflow: "initial" }}
        >
          {content}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutG;

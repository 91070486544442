import React, { useEffect, useState } from "react";
import {
  YoutubeFilled,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Input, Button, Form, Image, Space, Typography } from "antd";
import "./Login.css";
import logodark from "../../assets/img/logo-dark.png";
import email_icon from "../../assets/svg/email-icon.svg";
import password_icon from "../../assets/svg/password-icon.svg";
import { Base64 } from "js-base64";

const Login = () => {
  let navigate = useNavigate();

  const [loadings, setLoadings] = useState(false);
  const [err, setErr] = useState();
  const [errG, seterrG] = useState(null);
  const [hide, setHide] = useState(true);

  const onFinish = (values) => {
    setLoadings(true);

    fetch(`${process.env.REACT_APP_URL}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        user: {
          email: values.correo,
          password: values.contraseña,
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (!json.error) {
          sessionStorage.setItem("1nf0", json.tkn);
          localStorage.setItem("username", Base64.encode(values.correo));
          localStorage.setItem("r0l", Base64.encode(json.rol));
          navigate("/dashboard", { replace: true });
        } else {
          setErr(json.error);
          setLoadings(false);
          throw new Error("Bad Response from server");
        }
      })
      .catch((error) => {
        setHide(false);
        setLoadings(false);
        seterrG("Hubo un percance con los datos enviados.");
      });
  };

  const onFinishFailed = (errorInfo) => {
    setLoadings(false);
  };

  useEffect(() => {
    if (hide === false) {
      setTimeout(() => {
        setHide(true);
        setErr(null);
      }, 3200);
    }
  }, [hide]);

  const { Text } = Typography;

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Col xs={0} sm={0} md={12} lg={12} xl={12} className="col-login">
          <div className="bg-left">
            <div className="login-wrapper-txt">
              <div className="text-wrapper">
                <Text className="bg-left-text">
                  Creemos
                  <br />
                  que las buenas
                  <br />
                  conexiones inspiran
                  <br />
                  ideas increíbles
                </Text>

                <Text className="l-txt-b" style={{ opacity: 1 }}>
                  Bienvenidos al portal de
                  <span> Facturación.</span>
                </Text>
              </div>
              <Space direction="vertical" size={0}>
                <Text
                  className="l-txt"
                  style={{
                    opacity: "0.7",
                  }}
                >
                  Corporación Matrix TV C.A Rif: J-297655948 | Conexsoft -
                  Proham
                </Text>
                <Text
                  className="l-txt"
                  style={{
                    opacity: "0.6",
                  }}
                >
                  Todos los derechos reservados © 2023
                </Text>
              </Space>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className={"login-form"}
          >
            <Image
              src={logodark}
              preview={false}
              className={"dark-logo"}
              alt="logo"
            />

            <Form.Item
              className="form-item"
              name="correo"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                  message:
                    "Ingrese una dirección de correo electrónico válida (por ejemplo, airtek@airtek.com.ve).",
                },
              ]}
            >
              <Input
                prefix={
                  <Image src={email_icon} preview={false} alt="email icon" />
                }
                className={"form-input"}
                placeholder="Correo electrónico"
              />
            </Form.Item>

            <Form.Item
              className={"form-item"}
              name="contraseña"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(/^\S*$/),
                  message: "Ingrese un formato válido.",
                },
                {
                  max: 12,
                  message: `Este campo debe tener como máximo 12 caracteres.`,
                },
                {
                  min: 8,
                  message: `Este campo debe tener como mínimo 8 caracteres.`,
                },
              ]}
            >
              <Input.Password
                prefix={
                  <Image
                    src={password_icon}
                    preview={false}
                    alt="password icon"
                  />
                }
                className={"form-input"}
                placeholder="Contraseña"
              />
            </Form.Item>

            {(err !== undefined || errG !== null) && hide === false && (
              <Text className="error"> {err ? err : errG} </Text>
            )}

            <Form.Item>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                className={"dark-btn"}
                loading={loadings}
                block
              >
                Iniciar Sesión
              </Button>
            </Form.Item>
          </Form>

          <Row>
            <Space
              direction="vertical"
              align="center"
              size={1}
              className={"sm-footer-container"}
            >
              <Link to="/recovery" className="o-pwd">
                ¿Olvidaste tu contraseña?
              </Link>
            </Space>
          </Row>
          <Row className={"sm-footer"}>
            <Space
              direction="vertical"
              align="center"
              size={1}
              className={"sm-footer-container"}
            >
              <Text strong> www.airtek.com.ve</Text>
              <Text>Síguenos en nuestras Redes Sociales</Text>

              <Space size={9}>
                <Button
                  type="link"
                  href="https://www.instagram.com/airtekinternet/"
                  icon={<InstagramOutlined className="icon-sm" />}
                />

                <>
                  <Button
                    type="link"
                    href="https://www.facebook.com/airtekesinternet/"
                    icon={<i className="fa-brands fa-facebook icon-sm"></i>}
                  />
                </>

                <Button
                  type="link"
                  href="https://www.youtube.com/c/AirtekesInternet"
                  icon={<YoutubeFilled className="icon-sm" />}
                />

                <Button
                  type="link"
                  href="https://twitter.com/airtekinternet"
                  icon={<TwitterOutlined className="icon-sm" />}
                />

                <>
                  <Button
                    type="link"
                    href="https://t.me/airtekinternet"
                    icon={<i className="fa-brands fa-telegram icon-sm"></i>}
                  />
                </>
              </Space>
            </Space>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Login;

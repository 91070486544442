import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import InvoicesCN from "../../components/InvoicesCN/InvoicesCN";
import LayoutG from "../../components/ui/Layout/Layout";

const Main = () => {
  const [userL, setuserL] = useState(null);

  useEffect(() => {
    var user = localStorage.getItem("username");

    if (user && sessionStorage.length !== 0) {
      setuserL(true);
    }
  }, []);

  const items = [
    {
      key: "1",
      label: `Facturas`,
      children: <InvoicesCN type={"1"} />,
    },
    {
      key: "2",
      label: `Notas de crédito`,
      children: <InvoicesCN type={"2"} />,
    },
  ];

  const MainContent = ({ info }) => {
    return (
      <>
        <Tabs defaultActiveKey="1" items={info} />
      </>
    );
  };

  return (
    <>{!userL ? <> </> : <LayoutG content={<MainContent info={items} />} />}</>
  );
};

export default Main;

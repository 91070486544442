import React, { useState } from "react";
import { Space, Spin, Select, Modal, Result, Button, Form, Input } from "antd";
import { Base64 } from "js-base64";
const { TextArea } = Input;

const Create = () => {
  const [reqRes, setreqRes] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    if (reqRes && reqRes.status === 204) {
      form.resetFields();
      setreqRes(null);
    }
  };

  const onFinish = (values) => {
    const user = Base64.decode(localStorage.getItem("username"));
    values["Usergen"] = user;
    setloading(true);
    fetch(`${process.env.REACT_APP_URL}create-users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authentication: `${sessionStorage.getItem("1nf0")}`,
      },

      body: JSON.stringify({
        info: values,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setloading(false);
        setreqRes(res);
        showModal();
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form name="basic" onFinish={onFinish} layout="vertical" form={form}>
          <Space className="space-form">
            <Form.Item
              label="Nombre"
              name="Nombre"
              rules={[
                {
                  required: true,
                },
                {
                  max: 100,
                  message: `Este campo debe tener como máximo 100 caracteres.`,
                },
                {
                  min: 3,
                  message: `Este campo debe tener como mínimo 3 caracteres.`,
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <Input className={"form-input-2"} />
            </Form.Item>

            <Form.Item
              label="Apellido"
              name="Apellido"
              rules={[
                {
                  required: true,
                },
                {
                  max: 100,
                  message: `Este campo debe tener como máximo 100 caracteres.`,
                },
                {
                  min: 3,
                  message: `Este campo debe tener como mínimo 3 caracteres.`,
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <Input className={"form-input-2"} />
            </Form.Item>
          </Space>

          <Space className="space-form">
            <Form.Item
              required
              name="Correo"
              label="Correo"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                  message:
                    "Ingrese una dirección de correo electrónico válida (por ejemplo, airtek@airtek.com.ve).",
                },
                {
                  max: 50,
                  message: `Este campo debe tener como máximo 50 caracteres.`,
                },
              ]}
            >
              <Input className={"form-input-2"} />
            </Form.Item>

            <Form.Item
              name="Roles"
              label="Rol"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                className="select-r"
                placeholder="Seleccionar Rol"
                options={[
                  { value: "004", label: "GERENTES" },
                  { value: "006", label: "ASISTENTE CONTABLE" },
                  { value: "031", label: "CLIENTE" },
                ]}
              ></Select>
            </Form.Item>
          </Space>

          <Space className="space-form">
            <Form.Item
              label="Contraseña"
              name="Password"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(/^\S*$/),
                  message: "Ingrese un formato válido.",
                },
                {
                  max: 12,
                  message: `Este campo debe tener como máximo 12 caracteres.`,
                },
                {
                  min: 8,
                  message: `Este campo debe tener como mínimo 8 caracteres.`,
                },
              ]}
            >
              <Input.Password
                className={"form-input-2"}
                placeholder="Contraseña"
              />
            </Form.Item>

            <Form.Item
              label="Confirmar Contraseña"
              name="Re_Password"
              rules={[
                {
                  required: true,
                },
                {
                  max: 12,
                  message: `Este campo debe tener como máximo 12 caracteres.`,
                },
                {
                  min: 8,
                  message: `Este campo debe tener como mínimo 8 caracteres.`,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value !== getFieldValue("Password")) {
                      return Promise.reject(
                        `Los campos "Contraseña" y "Confirmar Contraseña" no coinciden.`
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input.Password className={"form-input-2"} />
            </Form.Item>
          </Space>

          <Space className="space-form">
            <Form.Item
              label="Teléfono"
              name="Telefono"
              rules={[
                {
                  pattern: new RegExp(/^[0-9\b]+$/),
                  message: "Ingrese un número de teléfono válido.",
                },
                {
                  max: 10,
                  message: `Este campo debe tener como máximo 10 caracteres.`,
                },
              ]}
            >
              <Input className={"form-input-2"} />
            </Form.Item>

            <Form.Item
              label="Comentario"
              name="Comentario"
              rules={[
                {
                  max: 150,
                  message: `Este campo debe tener como máximo 150 caracteres.`,
                },
              ]}
            >
              <Input className={"form-input-2"} />
            </Form.Item>
          </Space>

          <Form.Item
            label="Dirección"
            name="Direccion"
            rules={[
              {
                max: 150,
                message: `Este campo debe tener como máximo 150 caracteres.`,
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 2, maxRows: 2 }}
              className={"form-textarea"}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              className={"dark-btn"}
              style={{
                width: "100%",
              }}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>

        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          maskClosable={false}
          footer={null}
        >
          <Result
            status={
              reqRes && reqRes.status === 204
                ? "success"
                : reqRes && reqRes.status === 400
                ? "info"
                : "error"
            }
            title={
              reqRes && reqRes.status === 204
                ? "Usuario creado con éxito"
                : reqRes && reqRes.msj
                ? reqRes.msj.replace(/['"]+/g, "")
                : ""
            }
            subTitle={
              reqRes && reqRes.status === 204
                ? `Usuario para iniciar sesión: ${reqRes.user}`
                : ""
            }
          />
        </Modal>
      </Spin>
    </>
  );
};

export default Create;

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Input,
  Button,
  Form,
  Image,
  Space,
  Typography,
  Modal,
  Popconfirm,
  Result,
} from "antd";
import video from "../../assets/videos/pwd-vid.mp4";
import back from "../../assets/svg/back.svg";
import { useNavigate } from "react-router-dom";
import "./ChangePwd.css";
import { Base64 } from "js-base64";
const { Text } = Typography;

const ChangePwd = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [msj, setMsj] = useState("");
  const [reqStatus, setreqStatus] = useState(null);
  const [userL, setuserL] = useState(null);

  useEffect(() => {
    var user = localStorage.getItem("username");
    if (user && sessionStorage.length !== 0) {
      setuserL(true);
    }
  }, []);

  const onFinish = (values) => {
    let base64ToStringNew = Base64.decode(localStorage.getItem("username"));

    values = { ...values, UserName: base64ToStringNew };

    var pageView = sessionStorage.getItem("1nf0");
    fetch(`${process.env.REACT_APP_URL}change-pwd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authentication: `${pageView}`,
      },
      body: JSON.stringify({
        info: values,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        showModal();
        setreqStatus(data.status);

        if (data.status === 204) {
          setMsj("La contraseña se ha cambiado exitosamente.");
        } else if (data.status === 400) {
          setMsj("Hubo un percance con los datos enviados.");
          throw new Error("Bad response from server");
        }
        if (data.status === 404) {
          setMsj(
            "La contraseña actual que especificaste es incorrecta. Inténtalo de nuevo."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirm = () => {
    form.submit();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    if (reqStatus === 204) {
      navigate("/dashboard", { replace: true });
    }
  };

  const Modal1 = () => {
    return (
      <>
        <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
          <Result
            status={
              reqStatus === 204
                ? "success"
                : reqStatus === 400
                ? "error"
                : "info"
            }
            title={msj}
          />
        </Modal>
      </>
    );
  };

  return (
    <>
      {!userL ? (
        <> </>
      ) : (
        <div className={"container-pwd"}>
          <nav className={"nav-pwd"}>
            <div className={"nav-pwd-wrapper"}>
              <div
                className={"btn-back"}
                onClick={() => {
                  navigate("/dashboard", { replace: true });
                }}
              >
                <Image src={back} preview={false} alt="return"/>
                Regresar
              </div>
            </div>
          </nav>

          <div className={"container-pwd-form"}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  className="form-pwd"
                  form={form}
                >
                  <Text className="title-cpwd">Restablecer contraseña</Text>

                  <Text className="text-cpwd">
                    Ingrese los valores solicitados para proceder con el{" "}
                    <Text className="text-cpwd" strong>
                      cambio de clave.
                    </Text>
                  </Text>

                  <Form.Item
                    className={"form-item"}
                    name="Password"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        max: 12,
                        message: `Este campo debe tener como máximo 12 caracteres.`,
                      },
                      {
                        min: 8,
                        message: `Este campo debe tener como mínimo 8 caracteres.`,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Contraseña actual"
                      className={"form-input"}
                    />
                  </Form.Item>
                  <Form.Item
                    className={"form-item"}
                    name="Re_Password"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        max: 12,
                        message: `Este campo debe tener como máximo 12 caracteres.`,
                      },
                      {
                        min: 8,
                        message: `Este campo debe tener como mínimo 8 caracteres.`,
                      },

                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            value === getFieldValue("Password") &&
                            value &&
                            getFieldValue("Password") !== ""
                          ) {
                            return Promise.reject(
                              "La contraseña nueva debe ser distinta de la contraseña anterior.                        "
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Nueva contraseña"
                      className={"form-input"}
                    />
                  </Form.Item>
                  <Form.Item
                    className={"form-item"}
                    name="Re_Password2"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        max: 12,
                        message: `Este campo debe tener como máximo 12 caracteres.`,
                      },
                      {
                        min: 8,
                        message: `Este campo debe tener como mínimo 8 caracteres.`,
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value !== getFieldValue("Re_Password")) {
                            return Promise.reject(
                              `Los campos "Nueva Contraseña" y "Confirmar Contraseña" no coinciden.`
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirmar contraseña"
                      className={"form-input"}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Popconfirm
                      placement="bottomRight"
                      title={"¿Desea cambiar su contraseña?"}
                      onConfirm={confirm}
                      okText="Si"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        shape="round"
                        className={"dark-btn"}
                        block
                      >
                        Cambiar Contraseña
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={0} sm={0} md={0} lg={12} xl={12}>
                <video autoPlay loop muted className="my-auto pwd-vid">
                  <source src={video} type="video/mp4" />
                  Su navegador no soporta la etiqueta de vídeo.
                </video>
              </Col>
            </Row>

            <Row type="flex" align="middle">
              <Space direction="vertical" size={0} style={{ display: "flex" }}>
                <Text>Tu clave de acceso es personal e intransferible.</Text>
                <Text>
                  La contraseña debería tener como mínimo de 8 a 12 caracteres
                  alfanuméricos.
                </Text>
                <Text>
                  Evita utilizar palabras o nombres que sean fáciles de
                  adivinar.
                </Text>
              </Space>
            </Row>
            <Modal1 />
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePwd;

import React, { useState, useEffect } from "react";
import { Base64 } from "js-base64";
import { Tabs } from "antd";
import LayoutG from "../../components/ui/Layout/Layout";
import "./Users.css";
import Update from "./UserActions/Update";
import Create from "./UserActions/Create";

const Users = () => {
  const [userL, setuserL] = useState(null);
  const [rol] = useState(Base64.decode(localStorage.getItem("r0l")));

  useEffect(() => {
    var user = localStorage.getItem("username");

    if (user && sessionStorage.length !== 0) {
      setuserL(true);
    }
  }, []);

  const additionalI = {
    key: "2",
    label: `Editar Usuario`,
    children: <Update />,
  };

  let items = [
    {
      key: "1",
      label: `Crear Usuarios`,
      children: <Create />,
    },
  ];

  if (rol === "004") {
    items = items.concat(additionalI);
  }

  const MainContent = ({ info }) => {
    return (
      <div
        style={{
          width: "70%",
          margin: "auto",
        }}
      >
        <Tabs defaultActiveKey="1" items={info} />
      </div>
    );
  };

  return (
    <>
      {!userL || rol !== "004" ? (
        <> </>
      ) : (
        <LayoutG content={<MainContent info={items} />} />
      )}
    </>
  );
};

export default Users;

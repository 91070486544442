import React, { useState, useEffect, useRef } from "react";
import {
  DatePicker,
  Space,
  Table,
  Spin,
  Select,
  Modal,
  Result,
  Button,
  Form,
  Input,
} from "antd";
import { Base64 } from "js-base64";
import "dayjs/locale/es";
import "./InvoicesCN.css";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import { MailFilled, DownloadOutlined, FormOutlined } from "@ant-design/icons";
import { DecodePDF } from "../../functions/DecodePDF";
const { TextArea } = Input;
const dateFormat = "DD-MM-YYYY";
const { RangePicker } = DatePicker;

function disabledDate(current) {
  return current > moment();
}

const InvoicesCN = ({ type }) => {
  const [sentMail, setsentMail] = useState(false);
  const [pagec, setpage] = useState(1);
  const [dateRange, setdateRange] = useState([]);
  const [xpage, setxpage] = useState(5);
  const [info, setinfo] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [loading, setLoading] = useState(false);
  const [searchType, setsearchType] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reqStatus, setreqStatus] = useState(null);
  const [msj, setMsj] = useState("");
  const [clickedRec, setclickedRec] = useState(null);
  const [pdf, setpdf] = useState(null);
  const [isModalUOpen, setIsModalUOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updateT, setupdateT] = useState(false);
  const [updD, setUpd] = useState(false);
  const disabledRef = useRef(0);

  // open modal response
  const showModal = () => {
    setIsModalOpen(true);
  };

  // modal upd data
  const showModalU = () => {
    if (!loading) {
      setIsModalUOpen(true);
    }
  };

  // close modals and clean state
  const handleCancel = () => {
    setIsModalOpen(false);
    setMsj("");
    setSelected(null);
    setclickedRec(null);
    setIsModalUOpen(false);
    setreqStatus(null);

    if (updD) {
      setupdateT(true);
    }
  };

  // change table page
  const onChange = (page1, size) => {
    setpage(page1);
    setxpage(size);
  };

  // re send document - get selected record
  const sendMail = (record) => {
    setsentMail(true);
    fetch(`${process.env.REACT_APP_URL}sendDoc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authentication: `${sessionStorage.getItem("1nf0")}`,
      },
      body: JSON.stringify({
        info: record,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        showModal();
        setsentMail(false);
        setreqStatus(json.status);

        // show modal - msg based on response code
        if (json.status === 200) {
          setMsj("Se ha enviado exitosamente.");
        } else if (json.status === 404) {
          setMsj("El documento ingresado no existe.");
        } else if (json.status === 406) {
          setMsj("No se ha podido enviar el correo. Correo inválido.");
        } else if (json.status === 403) {
          setMsj("No cuenta con los privilegios solicitados.");
        } else {
          setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
        }
      })
      .catch((error) => {
        setsentMail(false);
        setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
        showModal();
        console.log(error);
      });
  };

  // download document
  const downloadDoc = (record) => {
    fetch(`${process.env.REACT_APP_URL}downloadDoc/${type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authentication: `${sessionStorage.getItem("1nf0")}`,
      },
      body: JSON.stringify({
        info: record,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.archivo !== "") {
          const res_pdf = await DecodePDF(res.archivo);

          // pdf document
          setpdf(res_pdf);

          const downloadLink = document.createElement("a");
          const fileName = `${record.Factura}.pdf`;

          downloadLink.href = res_pdf;
          downloadLink.download = fileName;
          downloadLink.click();

          setpdf(null);
          setclickedRec(null);
        } else {
          setclickedRec(null);
          showModal();
          setreqStatus(400);
          setMsj(res.mensaje);
        }
      })
      .catch((error) => {
        setclickedRec(null);
        setreqStatus(404);
        showModal();
        setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
        console.log(error);
      });
  };

  // table headers
  const columns = [
    {
      title: "Identificador",
      dataIndex: "Identificador",
      align: "center",
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      align: "center",
    },
    {
      title: "Cédula",
      dataIndex: "Cedula",
      sorter: (a, b) => a.Cedula.localeCompare(b.Cedula),
      align: "center",
    },
    {
      title: "Documento",
      dataIndex: "Factura",
      sorter: (a, b) => a.Factura.localeCompare(b.Factura),
      align: "center",
    },
    {
      title: "Mes",
      dataIndex: "Mes",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      align: "center",
    },
    {
      title: "Estatus",
      dataIndex: "Status",
      align: "center",
    },
    {
      title: "Mensaje",
      dataIndex: "Mensaje",
      align: "center",
    },
    {
      title: "Validaciones",
      dataIndex: "Validaciones",
      align: "center",
      render: (text) => {
        return <Space className="column-custom">{text}</Space>;
      },
    },
    {
      title: "Fecha Imprenta",
      dataIndex: "FechaImprenta",
      align: "center",
    },
    {
      title: "Hora",
      dataIndex: "Hora",
      align: "center",
    },
    {
      title: "Acción",
      key: "accion",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {record && parseInt(record.Status) === 200 && (
            <>
              <div
                className={`${clickedRec ? "row-selected" : ""}`}
                onClick={() => {
                  sendMail(record);
                  if (clickedRec === null) {
                    setclickedRec(record.Identificador);
                  }
                }}
              >
                <MailFilled
                  style={{
                    cursor: "pointer",
                    opacity: clickedRec ? "0.1" : "1",
                  }}
                  className={`${
                    clickedRec === record.Identificador && sentMail === true
                      ? "icon-selected"
                      : ""
                  }`}
                />
              </div>
              <div
                className={`${clickedRec ? "row-selected" : ""}`}
                onClick={() => {
                  downloadDoc(record);
                  if (clickedRec === null) {
                    setclickedRec(record.Identificador);
                  }
                }}
              >
                <DownloadOutlined
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    opacity: clickedRec ? "0.1" : "1",
                  }}
                  className={`${
                    clickedRec === record.Identificador && pdf !== ""
                      ? "icon-selected"
                      : ""
                  }`}
                />
              </div>
            </>
          )}

          {record && parseInt(record.Status) === 201 && (
            <>
              <div
                className={`${clickedRec ? "row-selected" : ""}`}
                onClick={() => {
                  showModalU();
                  setSelected(record);
                  if (clickedRec === null) {
                    setclickedRec(record.Identificador);
                  }
                }}
              >
                <FormOutlined
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    opacity: clickedRec ? "0.1" : "1",
                  }}
                  className={`${
                    clickedRec === record.Identificador && isModalUOpen
                      ? "icon-selected"
                      : ""
                  }`}
                />
              </div>
            </>
          )}
        </Space>
      ),
    },
  ];

  // get date range
  function onSelectDate(date, dateString) {
    if (date === null) {
      setdateRange([undefined, undefined]);
    } else {
      setdateRange(dateString);
    }
  }

  useEffect(() => {
    const getData = () => {
      setLoading(true);

      var url = `${process.env.REACT_APP_URL}invoices/${xpage}/${pagec}/${dateRange[0]}/${dateRange[1]}/${searchType}/${type}`;

      fetch(url, {
        method: "GET",
        headers: { Authentication: `${sessionStorage.getItem("1nf0")}` },
      })
        .then((response) => response.json())
        .then((json) => {
          setLoading(false);
          setinfo(json.records);
          settotalPages(json.total_records);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };

    getData();

    // update table after updating a record
    if (updateT) {
      getData();
      setupdateT(false);
      setUpd(false);
    }
  }, [dateRange, pagec, xpage, searchType, type, updateT]);

  // modal that displays success / error / info
  const Modal1 = () => {
    return (
      <>
        <Modal
          open={isModalOpen}
          centered
          maskClosable={false}
          onCancel={handleCancel}
          footer={null}
        >
          {reqStatus && (
            <Result
              status={
                reqStatus === 200
                  ? "success"
                  : reqStatus === 404
                  ? "error"
                  : "info"
              }
              title={msj}
            />
          )}
        </Modal>
      </>
    );
  };

  // modal upd data
  const ModalUpd = () => {
    const onFinish = (values) => {
      // disable button
      disabledRef.current.style.pointerEvents = "none";
      disabledRef.current.style.opacity = "0.5";

      const user = Base64.decode(localStorage.getItem("username"));

      // get info from localStorage
      values["usuario"] = user;
      values["prefijo"] = selected.Prefijo;
      values["codigo"] = selected.Codigo;

      fetch(`${process.env.REACT_APP_URL}updData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${sessionStorage.getItem("1nf0")}`,
        },

        body: JSON.stringify({
          info: values,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          // show button
          disabledRef.current.style.pointerEvents = "auto";
          disabledRef.current.style.opacity = "1";

          setSelected(null);
          setIsModalUOpen(false);
          setreqStatus(res.status);
          showModal()
          setUpd(true);

          if (res.status === 403) {
            setSelected(null);
            setMsj("No cuenta con los privilegios solicitados");
          } else {
            setSelected(null);
            setMsj(res.msj.replace(/['"]+/g, ""));
          }
        })
        .catch((error) => {
          setIsModalUOpen(false);
          disabledRef.current.style.pointerEvents = "auto";
          disabledRef.current.style.opacity = "1";
          setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
          setreqStatus(404);
          showModal();
        });
    };

    return (
      <>
        <Modal
          open={isModalUOpen}
          centered
          onCancel={handleCancel}
          transitionName=""
          footer={null}
          maskClosable={false}
        >
          <>
            <p className="title-form">Actualizar Datos</p>

            <Form
              name="basic"
              onFinish={onFinish}
              layout="vertical"
              initialValues={{
                telefono: selected && selected.Telefono,
                correo: selected && selected.Correo,
                nombre1:
                  selected &&
                  (selected.TipoIdentificacion === "J-" ||
                  selected.TipoIdentificacion === "J"
                    ? selected.RazonSocial
                    : selected.PrimerNombre),
                nombre2: selected && selected.SegundoNombre,
                apellido1: selected && selected.PrimerApellido,
                apellido2: selected && selected.SegundoApellido,
                direccion: selected && selected.Direccion,
                cedula: selected && selected.Cedula.trim(),
              }}
            >
              {selected && (
                <>
                  <Form.Item
                    required
                    name="correo"
                    label="Correo"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "email",
                        message:
                          "Ingrese una dirección de correo electrónico válida (por ejemplo, airtek@airtek.com.ve).",
                      },
                      {
                        max: 50,
                        message: `Este campo debe tener como máximo 50 caracteres.`,
                      },
                    ]}
                  >
                    <Input className={"form-input-2"} />
                  </Form.Item>

                  <Space className="space-form">
                    <Form.Item
                      label={
                        (selected && selected.TipoIdentificacion === "J-") ||
                        selected.TipoIdentificacion === "J"
                          ? "Razón Social"
                          : "Primer Nombre"
                      }
                      name="nombre1"
                      rules={[
                        {
                          max: 100,
                          message: `Este campo debe tener como máximo 100 caracteres.`,
                        },
                      ]}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Input className={"form-input-2"} />
                    </Form.Item>

                    {selected &&
                      selected.TipoIdentificacion !== "J-" &&
                      selected.TipoIdentificacion !== "J" && (
                        <Form.Item
                          label="Segundo Nombre"
                          name="nombre2"
                          rules={[
                            {
                              max: 100,
                              message: `Este campo debe tener como máximo 100 caracteres.`,
                            },
                          ]}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Input className={"form-input-2"} />
                        </Form.Item>
                      )}
                  </Space>

                  {selected &&
                    selected.TipoIdentificacion !== "J-" &&
                    selected.TipoIdentificacion !== "J" && (
                      <>
                        <Space className="space-form">
                          <Form.Item
                            label="Primer Apellido"
                            name="apellido1"
                            rules={[
                              {
                                max: 100,
                                message: `Este campo debe tener como máximo 100 caracteres.`,
                              },
                            ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Input className={"form-input-2"} />
                          </Form.Item>

                          <Form.Item
                            label="Segundo Apellido"
                            name="apellido2"
                            rules={[
                              {
                                max: 100,
                                message: `Este campo debe tener como máximo 100 caracteres.`,
                              },
                            ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Input className={"form-input-2"} />
                          </Form.Item>
                        </Space>
                      </>
                    )}

                  <Space className="space-form">
                    <Form.Item
                      label="Teléfono"
                      name="telefono"
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9\b]+$/),
                          message: "Ingrese un número de teléfono válido.",
                        },
                        {
                          max: 10,
                          message: `Este campo debe tener como máximo 10 caracteres.`,
                        },
                      ]}
                    >
                      <Input className={"form-input-2"} />
                    </Form.Item>

                    <Form.Item
                      label={
                        (selected && selected.TipoIdentificacion === "J-") ||
                        selected.TipoIdentificacion === "J"
                          ? "RIF"
                          : "Cédula"
                      }
                      name="cedula"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          pattern: new RegExp(/^[0-9\b]+$/),
                          message: "Ingrese una cédula válida.",
                        },
                        {
                          max: 15,
                          message: `Este campo debe tener como máximo 15 caracteres.`,
                        },
                        {
                          min: 5,
                          message: `Este campo debe tener como mínimo 5 caracteres.`,
                        },
                      ]}
                    >
                      <Input
                        className={"form-input-2"}
                        disabled={selected && selected.Cedula !== ""}
                      />
                    </Form.Item>
                  </Space>

                  <Form.Item
                    label="Dirección"
                    name="direccion"
                    rules={[
                      {
                        max: 250,
                        message: `Este campo debe tener como máximo 250 caracteres.`,
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 2 }}
                      className={"form-textarea"}
                    />
                  </Form.Item>

                  <Form.Item>
                    <div ref={disabledRef}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        className={"dark-btn"}
                        style={{
                          width: "100%",
                        }}
                      >
                        Guardar
                      </Button>
                    </div>
                  </Form.Item>
                </>
              )}
            </Form>
          </>
        </Modal>
      </>
    );
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="inv-wrapper">
          <div className="date-search">
            <RangePicker
              locale={locale}
              onChange={onSelectDate}
              format={dateFormat}
              disabledDate={disabledDate}
              separator="-"
              style={{ width: 260 }}
              className="range-picker"
            />

            <Select
              defaultValue={{ value: 0, label: "Todos los documentos" }}
              className={"select-i"}
              onChange={(value) => {
                setsearchType(value);
                onChange(1, xpage);
              }}
              options={[
                { value: 0, label: "Todos los documentos" },
                { value: 1, label: "Documentos enviados" },
                { value: 2, label: "Documentos sin crear por validación" },
              ]}
            />
          </div>
        </div>

        <Table
          columns={columns}
          scroll={{ x: "100%" }}
          dataSource={info}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          rowKey="Identificador"
          pagination={{
            current: pagec,
            defaultPageSize: 5,
            showSizeChanger: true,
            total: totalPages,
            showTotal: (total) => `Total Registros: ${total}`,
            pageSizeOptions: ["5", "10", "20", "30", "50", "80", "100"],
            onChange: onChange,
          }}
        />
      </Spin>
      <Modal1 />
      <ModalUpd />
    </div>
  );
};

export default InvoicesCN;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    locale={es_ES}
    theme={{
      token: {
        colorPrimary: "#0066FF",
        fontFamily: "Montserrat",
      },
    }}
  >
    <App />
  </ConfigProvider>
);

import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import NotFound from "../components/ui/NotFound/NotFound";
import ChangePwd from "../pages/ChangePwd/ChangePwd";
import Login from "../pages/Login/Login";
import Main from "../pages/Main/Main";
import Users from "../pages/Users/Users";
import Recovery from "../pages/Recovery/Recovery";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Main />} />
          <Route exact path="/usuarios" element={<Users />} />
          <Route exact path="/chg-pwd" element={<ChangePwd />} />
          <Route exact path="/recovery" element={<Recovery />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;

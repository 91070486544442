import React, { useState } from "react";
import {
  Space,
  Button,
  Form,
  Input,
  Typography,
  Modal,
  Steps,
  Result,
  Spin,
  Select,
} from "antd";
import { Base64 } from "js-base64";

const Update = () => {
  const [current, setCurrent] = useState(0);
  const [first, setfirst] = useState(null);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
    setfirst(null);
  };

  const { Text } = Typography;

  const Search = () => {
    const [reqStatus, setreqStatus] = useState(null);
    const [loading, setloading] = useState(false);

    const onFinish = (values) => {
      setreqStatus(null);

      setloading(true);
      fetch(`${process.env.REACT_APP_URL}search-users/${values.Correo}/0`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${sessionStorage.getItem("1nf0")}`,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          setloading(false);
          setreqStatus(res.status);

          if (res.status === 200) {
            setfirst(res.msj);
            next();
          }
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    };

    return (
      <>
        <Spin spinning={loading}>
          <Form name="basic" onFinish={onFinish} layout="vertical">
            <Form.Item
              required
              name="Correo"
              label="Correo"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                  message:
                    "Ingrese una dirección de correo electrónico válida (por ejemplo, airtek@airtek.com.ve).",
                },
                {
                  max: 50,
                  message: `Este campo debe tener como máximo 50 caracteres.`,
                },
              ]}
            >
              <Input className={"form-input-2"} />
            </Form.Item>

            {reqStatus && reqStatus !== 200 && (
              <Text className="text-nf">
                No se ha encontrado registro asociado a la búsqueda.
              </Text>
            )}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                className={"dark-btn"}
                style={{
                  width: "100%",
                }}
              >
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </>
    );
  };

  const Upd = () => {
    const [loading, setloading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusU, setstatusU] = useState(null);
    const [msj, setMsj] = useState("");
    const [form] = Form.useForm();

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      if (statusU === 204) {
        prev();
        form.resetFields();
      }
      setMsj("");
      setIsModalOpen(false);
      setstatusU(null);
    };

    const onFinish = (values) => {
      const user_Id = first.Id;
      const user = Base64.decode(localStorage.getItem("username"));
      values["Usuario_modificacion"] = user;
      setloading(true);
      fetch(`${process.env.REACT_APP_URL}upd-users`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${sessionStorage.getItem("1nf0")}`,
        },

        body: JSON.stringify({
          info: values,
          id: user_Id,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          setloading(false);
          showModal();

          setstatusU(res.status);
          if (res.status === 204) {
            setMsj("Datos actualizados.");
          } else if (res.status === 403) {
            setMsj("No cuenta con los privilegios solicitados.");
          } else {
            setMsj("Ha ocurrido alguna eventualidad en el proceso.");
          }
        })
        .catch((error) => {
          setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
          setstatusU(404);
          showModal();
          setloading(false);
          console.log(error);
        });
    };

    return (
      <>
        <Spin spinning={loading}>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              Telefono: first && first.Telefono,
              Correo: first && first.Correo,
              Nombre: first && first.Nombre,
              Apellido: first && first.Apellido,
              Roles:
                first &&
                (first.Roles === "GERENTES"
                  ? "004"
                  : first.Roles === "ASISTENTE CONTABLE"
                  ? "006"
                  : "031"),
            }}
          >
            <Space className="space-form">
              <Form.Item
                label="Nombre"
                name="Nombre"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 100,
                    message: `Este campo debe tener como máximo 100 caracteres.`,
                  },
                  {
                    min: 3,
                    message: `Este campo debe tener como mínimo 3 caracteres.`,
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <Input className={"form-input-2"} />
              </Form.Item>

              <Form.Item
                label="Apellido"
                name="Apellido"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 100,
                    message: `Este campo debe tener como máximo 100 caracteres.`,
                  },
                  {
                    min: 3,
                    message: `Este campo debe tener como mínimo 3 caracteres.`,
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <Input className={"form-input-2"} />
              </Form.Item>
            </Space>

            <Space className="space-form">
              <Form.Item
                required
                name="Correo"
                label="Correo"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                    message:
                      "Ingrese una dirección de correo electrónico válida (por ejemplo, airtek@airtek.com.ve).",
                  },
                  {
                    max: 50,
                    message: `Este campo debe tener como máximo 50 caracteres.`,
                  },
                ]}
              >
                <Input className={"form-input-2"} />
              </Form.Item>

              <Form.Item
                label="Teléfono"
                name="Telefono"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(/^[0-9\b]+$/),
                    message: "Ingrese un número de teléfono válido.",
                  },
                  {
                    max: 11,
                    message: `Este campo debe tener como máximo 10 caracteres.`,
                  },
                  {
                    min: 10,
                    message: `Este campo debe tener como mínimo 10 caracteres.`,
                  },
                ]}
              >
                <Input className={"form-input-2"} />
              </Form.Item>
            </Space>

            <Space className="space-form" align={"start"}>
              <Form.Item
                name="Roles"
                label="Rol"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  className="select-r"
                  placeholder="Seleccionar Rol"
                  options={[
                    { value: "004", label: "GERENTES" },
                    { value: "006", label: "ASISTENTE CONTABLE" },
                    { value: "031", label: "CLIENTE" },
                  ]}
                ></Select>
              </Form.Item>
            </Space>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                className={"dark-btn"}
                style={{
                  width: "100%",
                }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Form>

          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            centered
            maskClosable={false}
            footer={null}
          >
            <Result
              status={
                statusU === 204 ? "success" : statusU === 400 ? "info" : "error"
              }
              title={msj}
            />
          </Modal>
        </Spin>
      </>
    );
  };

  const steps = [
    {
      title: "Buscar Usuario",
      content: <Search />,
    },
    {
      title: "Editar Datos",
      content: <Upd />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Regresar
          </Button>
        )}
      </div>
    </>
  );
};

export default Update;

import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  let navigate = useNavigate();
  return (
    <div className="result-wr">
      <Result
        className="result-container"
        status="404"
        title="404"
        subTitle="Lo sentimos, la página que ha visitado no existe."
        extra={
          <Button
            onClick={() => {
              navigate("/", { replace: true });
            }}
            className="btn-result"
          >
            Ir al inicio
          </Button>
        }
      />
    </div>
  );
};
export default NotFound;

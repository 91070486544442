import {
  Button,
  Steps,
  Spin,
  Form,
  Input,
  Typography,
  Result,
  Image,
  Modal,
} from "antd";
import { useState, useEffect } from "react";
import { Logout } from "../../functions/Logout";
import { useNavigate } from "react-router-dom";
import logodark from "../../assets/img/logo-dark.png";
import "./Recovery.css";

const Recovery = () => {
  const { Text } = Typography;
  let navigate = useNavigate();
  const [first, setfirst] = useState(null);
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
    setfirst(null);
  };

  const Search = () => {
    const [reqStatus, setreqStatus] = useState(null);
    const [loading, setloading] = useState(false);

    const onFinish = (values) => {
      setreqStatus(null);

      setloading(true);
      fetch(`${process.env.REACT_APP_URL}search-users/${values.Correo}/1`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${sessionStorage.getItem("1nf0")}`,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          sessionStorage.setItem("1nf0", res.inf);
          setloading(false);
          setreqStatus(res.status);

          if (res.status === 200) {
            setfirst(res.msj);
            next();
          }
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    };

    return (
      <>
        <Spin spinning={loading}>
          <Text className="txt-search-u">
            Por favor, ingresa el correo electrónico para recuperar tu
            contraseña.
          </Text>

          <Form name="basic" onFinish={onFinish} layout="vertical">
            <Form.Item
              required
              name="Correo"
              label="Correo"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                  message:
                    "Ingrese una dirección de correo electrónico válida (por ejemplo, airtek@airtek.com.ve).",
                },
                {
                  max: 50,
                  message: `Este campo debe tener como máximo 50 caracteres.`,
                },
              ]}
            >
              <Input className={"form-input-2"} />
            </Form.Item>

            {reqStatus && reqStatus !== 200 && (
              <Text className="text-nf">
                No se ha encontrado registro asociado a la búsqueda.
              </Text>
            )}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                className={"dark-btn"}
                style={{
                  width: "100%",
                }}
              >
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </>
    );
  };

  const ChangePwd = () => {
    const [reqRes, setreqRes] = useState(null);
    const [form] = Form.useForm();
    const [loading, setloading] = useState(false);
    const [mailReq, setmailReq] = useState(null);
    const [lMail, setlMail] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);

      if (mailReq && mailReq !== 204) {
        prev();
        form.resetFields();
      }

      if (reqRes && reqRes.status === 204) {
        form.resetFields();
        Logout();
        navigate("/", { replace: true });
      }
    };

    useEffect(() => {
      if (first) {
        setlMail(true);
        fetch(`${process.env.REACT_APP_URL}send-code`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authentication: `${sessionStorage.getItem("1nf0")}`,
          },

          body: JSON.stringify({
            info: {
              Destinatario: `${first.Nombre} ${first.Apellido}`,
              Email_Destinatario: first.Correo,
            },
            Userid: first.Userid,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            setlMail(false);

            setmailReq(res.status);

            if (res.status !== 204) {
              showModal();
            }
          })
          .catch((error) => {
            console.log(error);
            setlMail(false);
          });
      }
    }, []);

    const onFinish = (values) => {
      setloading(true);
      values["Nick"] = first.Nick;

      fetch(`${process.env.REACT_APP_URL}recover-pwd`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${sessionStorage.getItem("1nf0")}`,
        },

        body: JSON.stringify({
          info: values,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          setloading(false);
          setreqRes(res);
          showModal();
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    };

    return (
      <>
        <Spin spinning={lMail}>
          <>
            {mailReq === 204 && (
              <>
                <Text className="txt-search-u">
                  Se ha enviado un código de verificación al correo
                  anteriormente indicado.
                </Text>

                <Spin spinning={loading}>
                  <Form
                    name="basic"
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}
                  >
                    <Form.Item
                      name="Codigo_confirmacion"
                      label="Código de verificación"
                      className="form-rec-pwd"
                      rules={[
                        { required: true },

                        {
                          max: 10,
                          message: `Este campo debe tener como máximo 10 caracteres.`,
                        },
                        {
                          min: 10,
                          message: `Este campo debe tener como mínimo 10 caracteres.`,
                        },
                      ]}
                    >
                      <Input
                        className={"form-input-2"}
                        placeholder="123*456-aA"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Contraseña"
                      className="form-rec-pwd"
                      name="Password"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          pattern: new RegExp(/^\S*$/),
                          message: "Ingrese un formato válido.",
                        },
                        {
                          max: 12,
                          message: `Este campo debe tener como máximo 12 caracteres.`,
                        },
                        {
                          min: 8,
                          message: `Este campo debe tener como mínimo 8 caracteres.`,
                        },
                      ]}
                    >
                      <Input.Password
                        className={"form-input-2"}
                        placeholder="********"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Confirmar Contraseña"
                      className="form-rec-pwd"
                      name="Re_Password"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          max: 12,
                          message: `Este campo debe tener como máximo 12 caracteres.`,
                        },
                        {
                          min: 8,
                          message: `Este campo debe tener como mínimo 8 caracteres.`,
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value !== getFieldValue("Password")) {
                              return Promise.reject(
                                `Los campos "Contraseña" y "Confirmar Contraseña" no coinciden.`
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className={"form-input-2"}
                        placeholder="********"
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        className={"dark-btn"}
                        style={{
                          width: "100%",
                        }}
                      >
                        Guardar
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </>
            )}
          </>
          <Modal open={isModalOpen} footer={false} onCancel={handleCancel}>
            <Result
              status={
                mailReq && mailReq !== 204
                  ? "error"
                  : reqRes && reqRes.status === 204
                  ? "success"
                  : "error"
              }
              title={
                mailReq && mailReq !== 204
                  ? "No se ha podido enviar el correo. Vuelve a intentarlo más tarde."
                  : reqRes && reqRes.status === 204
                  ? "¡Datos Enviados!"
                  : reqRes && reqRes.msj
                  ? reqRes.msj
                  : ""
              }
            ></Result>
          </Modal>
        </Spin>
      </>
    );
  };

  const steps = [
    {
      title: "Buscar Usuario",
      content: <Search />,
    },
    {
      title: "Restablecer Contraseña",
      content: <ChangePwd />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        height: "100%",
        width: "auto",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Image
          src={logodark}
          preview={false}
          height={30}
          style={{
            marginBottom: "40px",
          }}
          // className={"dark-logo"}
          alt="logo"
        />

        <Steps
          current={current}
          items={items}
          size="small"
          className="steps-pwd"
        />
        <div
          style={{
            height: "100%",
          }}
        >
          {steps[current].content}
        </div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Regresar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recovery;
